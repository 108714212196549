import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private router: Router) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        if (currentUser && currentUser.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`
                }
            });
        }

        return next.handle(request).do(event => { }, err => {
            if (err instanceof HttpErrorResponse && (err.status === 401)) {
                const returnUrl = this.router.url;
                if (returnUrl.toLowerCase().indexOf('/login') === -1) {
                    this.router.navigate(['/login'], { queryParams: { returnUrl } });
                } else {
                    this.router.navigateByUrl('/login');
                }
            }
        });
    }
}
