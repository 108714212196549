import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MeasureDialogComponent } from '../measure-dialog/measure-dialog.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  model = {
    emailAddress: '',
    password: '',
    errorMessage: '' // used to display server generated messages
  };
  returnUrl: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    // reset login status
    this.authenticationService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  login() {
    this.authenticationService
      .login(this.model.emailAddress, this.model.password)
      .pipe(first())
      .subscribe(
        data => {
          this.router.navigate([this.returnUrl]);
        },
        error => {
          this.model.errorMessage = error;
        }
      );
  }

  //openMeasureDialog() {
  //  const measures = this.route.snapshot.data.measures;;
  //  const dialogRef = this.dialog.open(MeasureDialogComponent, {
  //    width: '500px',
  //    hasBackdrop: true,
  //    data: measures
  //  });

  //  dialogRef.afterClosed().subscribe(measure => {
  //    if (measure != null) {
  //      //this.model.selectedMeasure = measure;
  //    }
  //  });
  //this.model.selectedMeasure = 'AHS';
  //this.dataservice.getMeasures.subscribe()=

  //}
}
