import { Measure, MeasureGroup } from './../../classes/classes';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataService } from '../../../app/services/data.service';

@Component({
  selector: 'app-faq-dialog',
  templateUrl: './faq-dialog.component.html',
  styleUrls: ['./faq-dialog.component.css']
})
export class FAQDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<FAQDialogComponent>, public dataService: DataService) {

  }

  ngOnInit() { }

  onCloseClicked() {
    this.dialogRef.close();
  }



}
