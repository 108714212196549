import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import { DataService } from '../../services/data.service';
import { Observable, forkJoin } from 'rxjs';

@Injectable()
export class MapResolver implements Resolve<any> {
  constructor(private dataService: DataService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return forkJoin([
      this.dataService.getMeasureGroups(),
      this.dataService.getMeasures()
    ]).subscribe(data => {
      this.dataService.measureGroups = data[0];
      this.dataService.measures = data[1];
      this.dataService.appReady = true;
    });
  }
}
