import { Measure, MeasureGroup } from './../classes/classes';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class DataService {
  measureGroups: MeasureGroup[];
  measures: Measure[];
  selectedMeasureGroup: MeasureGroup;
  selectedMeasure: Measure;
  appReady = false;

  constructor(private http: HttpClient) {}

  getMeasures() {
    return this.http.get<Measure[]>(`${environment.apiBaseUrl}/measures`);
  }

  getMeasureGroups() {
    return this.http.get<MeasureGroup[]>(
      `${environment.apiBaseUrl}/measureGroups`
    );
  }

  getMeasuresByGroup(measureGroupId: number) {
    return this.measures.filter((measure: Measure) => {
      return measure.measureGroupId === measureGroupId;
    });
  }

  getLegendGrades(measureId: number) {
    return this.http.get<any[]>(
      `${environment.apiBaseUrl}/legendGrades/` + measureId.toString()
    );
  }

  getMeasureData(measureId: number) {
    var url = `${environment.apiBaseUrl}/measureData/` + measureId.toString() + `/Data`;
    return this.http.get<any[]>(url);
  }

  getPostCodes() {
    var url = `${environment.apiBaseUrl}/postcodes`;
    return this.http.get<any[]>(url); //{ responseType: 'text' as 'json' }
  }

  validatePostCode(postcode:string) {
    var url = `${environment.apiBaseUrl}/postcode/` + postcode.toString();
    return this.http.get<any[]>(url);
  }
}
