import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataService } from '../../../app/services/data.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import * as _ from 'lodash';

@Component({
  selector: 'app-postcode-dialog',
  templateUrl: './postcode-dialog.component.html',
  styleUrls: ['./postcode-dialog.component.css']
})
export class PostcodeDialogComponent implements OnInit {

  userPostcode: string;

  constructor(public dialogRef: MatDialogRef<PostcodeDialogComponent>, public dataService: DataService, private localStorage: LocalStorageService) { }

  ngOnInit() {
    if (this.localStorage.retrieve('userPostcode')) {
      this.userPostcode = this.localStorage.retrieve('userPostcode').postcode;
    }
  }

  onOKClicked() {
    this.dataService.validatePostCode(this.userPostcode).subscribe(
      userPostcode => {
        if (userPostcode) {
          this.localStorage.store('userPostcode', userPostcode);
          this.dialogRef.close(userPostcode);
        }
        else {
          this.dialogRef.close(null);
        }
        
      },
      error => { console.log("Error in fetching list of Postcodes");});
  }

  onCloseClicked() {
    this.dialogRef.close(null);
  }

}
