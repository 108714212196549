export interface MeasureGroup {
  measureGroupId: number;
  measureGroupName: string;
  description: string;
}

export interface Measure {
  measureGroupId: number;
  measureId: number;
  measureName: string;
  description: string;
  overlayId: number;
  MeasureDataFormat: string;
}

export interface LegendGrade {
  gradeValue: string;
  gradeValueFormat: string;
  legendColor: string;
  orderBy: number;
}

export enum OverLay {
  State = 1,
  LGA = 2,
  PostCode = 3
}
