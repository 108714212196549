import { Measure, MeasureGroup } from './../../classes/classes';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataService } from '../../../app/services/data.service';

@Component({
  selector: 'app-about-dialog',
  templateUrl: './about-dialog.component.html',
  styleUrls: ['./about-dialog.component.css']
})
export class AboutDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<AboutDialogComponent>, public dataService: DataService) {

  }

  ngOnInit() { }

  onCloseClicked() {
    this.dialogRef.close();
  }



}
