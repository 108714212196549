import { LoginComponent } from './components/login/login.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './classes/auth.guard';
import { MapComponent } from './components/map/map.component';
import { MapResolver } from './components/map/map-resolver';

const appRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    component: MapComponent,
    canActivate: [AuthGuard],
    resolve: {
      measureGroups: MapResolver
    }
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { initialNavigation: true })],
  providers: [],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule {}
