import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { AppRoutingModule } from './app-routing.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MapComponent } from './components/map/map.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MeasureDialogComponent } from './components/measure-dialog/measure-dialog.component';
import { SplashDialogComponent } from './components/splash-dialog/splash-dialog.component';
import { AboutDialogComponent } from './components/about-dialog/about-dialog.component';
import { FAQDialogComponent } from './components/faq-dialog/faq-dialog.component';
import { PostcodeDialogComponent } from './components/postcode-dialog/postcode-dialog.component';
import { MatListModule } from '@angular/material/list';
import { MapResolver } from './components/map/map-resolver';
import { JwtInterceptor } from './classes/jwt.interceptor';
import { ErrorInterceptor } from './classes/error.interceptor';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { MatToolbarModule, MatSidenavModule, MatMenuModule} from '@angular/material';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MapComponent,
    MeasureDialogComponent,
    SplashDialogComponent,
    AboutDialogComponent,
    FAQDialogComponent,
    PostcodeDialogComponent
  ],
  entryComponents: [MeasureDialogComponent, SplashDialogComponent, AboutDialogComponent, FAQDialogComponent, PostcodeDialogComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    FlexLayoutModule,
    LeafletModule.forRoot(),
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatListModule,
    MatCheckboxModule,
    NgxWebstorageModule.forRoot(),
    MatToolbarModule, MatSidenavModule, MatMenuModule
  ],
  providers: [
    MapResolver,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
