import { Measure, MeasureGroup } from './../../classes/classes';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataService } from '../../../app/services/data.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-splash-dialog',
  templateUrl: './splash-dialog.component.html',
  styleUrls: ['./splash-dialog.component.css']
})
export class SplashDialogComponent implements OnInit {

  doNotShowAgain: boolean = false;

  constructor(public dialogRef: MatDialogRef<SplashDialogComponent>, public dataService: DataService, private localStorage: LocalStorageService ) {}

  ngOnInit() { }

  onCloseClicked() {
    this.localStorage.store('doNotShowAgain', this.doNotShowAgain);
    this.dialogRef.close();
  }
  
}
