import {
  Measure,
  LegendGrade,
  OverLay,
  MeasureGroup
} from './../../classes/classes';
import { map } from 'rxjs/operators';
import {
  Component,
  ViewEncapsulation,
  ChangeDetectorRef,
  OnInit,
  ChangeDetectionStrategy
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tileLayer, latLng } from 'leaflet';
import * as L from 'leaflet';
import * as RCZ from '../../../assets/leaflet.rightclickzoom.js';
//import * as CFS from '../../../assets/control.fullscreen.js';
import * as _ from 'lodash';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MeasureDialogComponent } from '../measure-dialog/measure-dialog.component';
import { SplashDialogComponent } from '../splash-dialog/splash-dialog.component';
import { AboutDialogComponent } from '../about-dialog/about-dialog.component';
import { FAQDialogComponent } from '../faq-dialog/faq-dialog.component';
import { PostcodeDialogComponent } from '../postcode-dialog/postcode-dialog.component';
import { DataService } from '../../../app/services/data.service';
import { Observable } from 'rxjs/Observable';
import { forkJoin } from 'rxjs';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MapComponent implements OnInit {
  title = 'BrokerInfoMap';
  map: L.Map;
  regions: any;
  geojson: any;
  results: any = [];
  infoControl: any;
  selectedRegions: any[] = [];
  legend: any;
  regionInput: any = '';
  lgaInput: string = '';
  options = {
    layers: [
      tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        minZoom: 4,
        maxZoom: 18,
        attribution: '...'
      })
    ],
    zoom: 10,
    center: latLng({ lat: -37.8453794, lng: 145.0457191 }),
    doubleRightClickZoom: true,
    fullscreenControl: true
  };
  measureGroups: MeasureGroup[];
  legendGrades: LegendGrade[] = [];
  // selectedMeasureGroup: MeasureGroup;
  // selectedMeasure: Measure;
  mouseOverLayer: any;
  overlay = '';
  geolocationPosition: any;
  logoUrl: string =
    'https://res.cloudinary.com/twistoflime/portal/site/HumanListening_Icon.svg';
  doNotShowAgain: boolean = false;
  showSpinner: boolean = false;

  constructor(
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    public dataService: DataService,
    private router: Router,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private localStorage: LocalStorageService
  ) {
    this.measureGroups = this.route.snapshot.data.measureGroups;
    const temp = RCZ;
    // This just ensures that the rightclickzoom library gets loaded when doing a production build when we aren't using require.
    // temp = CFS;
  }

  ngOnInit() {
    this.doNotShowAgain = this.localStorage.retrieve('doNotShowAgain');
    if (!this.doNotShowAgain) {
      setTimeout(() => this.openSplashDialog());
    } else {
      setTimeout(() => this.openMeasureDialog());
    }
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  openSplashDialog() {
    const dialogRef = this.dialog
      .open(SplashDialogComponent, {
        width: '900px',
        hasBackdrop: true,
        data: {}
      })
      .afterClosed()
      .subscribe(() => {
        this.openMeasureDialog();
      });
  }

  openAboutDialog() {
    const dialogRef = this.dialog
      .open(AboutDialogComponent, {
        width: '600px',
        hasBackdrop: true,
        data: {}
      })
      .afterClosed()
      .subscribe(() => {});
  }

  openFAQDialog() {
    const dialogRef = this.dialog
      .open(FAQDialogComponent, {
        width: '900px',
        hasBackdrop: true,
        data: {}
      })
      .afterClosed()
      .subscribe(() => {});
  }

  openMeasureDialog() {
    const dialogRef = this.dialog
      .open(MeasureDialogComponent, {
        width: '900px',
        height: '600px',
        maxHeight: '99%',
        hasBackdrop: true,
        data: this.measureGroups
      })
      .afterClosed()
      .subscribe((data: any) => {
        if (!data) {
          return;
        }
        this.showSpinner = true;
        const measureGroup: MeasureGroup = data.measureGroup;
        const measure: Measure = data.measure;
        if (measureGroup) {
          this.dataService.selectedMeasureGroup = measureGroup;
        }
        if (measure != null) {
          this.dataService.selectedMeasure = measure;
          this.results = null;
          this.legendGrades = null;

          forkJoin([
            this.dataService.getLegendGrades(measure.measureId),
            this.dataService.getMeasureData(measure.measureId)
          ]).subscribe(
            results => {
              const [legends, dataResults] = results;
              this.results = dataResults;
              if (this.results.length === 0) {
                alert('Error - No data found for the selected measure');
              }
              this.autoDefineLegend(legends);
              this.addLegend();

              if (!this.localStorage.retrieve('userPostcode')) {
                this.openPostCodeDialog();
              } else {
                let userPostCode: any = this.localStorage.retrieve(
                  'userPostcode'
                );
                let regionName: any =
                  this.overlay === 'LGA'
                    ? userPostCode.lga
                    : userPostCode.postcode;
                this.centreMap(regionName);
              }

              if (measure.overlayId === OverLay.State) {
                this.loadOverlay('State');
              } else if (measure.overlayId === OverLay.LGA) {
                this.loadOverlay('LGA');
              } else if (measure.overlayId === OverLay.PostCode) {
                this.loadOverlay('Postcode');
              }

              this.showSpinner = false;
            },
            error => {
              this.showSpinner = false;
              console.log('Error : ' + error);
            },
            () => {
              this.showSpinner = false;
              console.log('Get Legend and Measure Data completed');
              this.cdr.detectChanges();
            }
          );
        }
      });
  }

  openPostCodeDialog() {
    const dialogRef = this.dialog
      .open(PostcodeDialogComponent, {
        width: '300px',
        hasBackdrop: true,
        data: { overlay: this.overlay }
      })
      .afterClosed()
      .subscribe(selectedPostcode => {
        let regionName: string;
        if (selectedPostcode) {
          let regionName: any =
            this.overlay === 'LGA'
              ? selectedPostcode.lga
              : selectedPostcode.postcode;
          this.centreMap(regionName);
        }
      });
  }

  centreMap(regionName) {
    this.map.eachLayer((layer: any) => {
      if (layer.feature && layer.feature.properties.Name === regionName) {
        this.map.fitBounds(layer.getBounds());
      }
    });
    this.cdr.detectChanges();
  }

  loadOverlay(overlay: string) {
    this.overlay = overlay;
    this.map.eachLayer((mapLayer: any) => {
      if (mapLayer.feature) {
        this.map.removeLayer(mapLayer);
      }
    });
    let geoJSONFile = '';
    if (overlay === 'Postcode') {
      geoJSONFile = './assets/au-postcodes-small.json';
    } else {
      geoJSONFile = './assets/au-councils.json';
    }

    this.http.get(geoJSONFile).subscribe(data => {
      this.regions = data;
      this.geojson = L.geoJSON(this.regions, {
        style: this.style.bind(this),
        onEachFeature: this.oneachfeature.bind(this)
      }).addTo(this.map);
    });
    this.showSpinner = false;
    this.cdr.detectChanges();
  }

  oneachfeature(feature: any, layer: any) {
    layer.on({
      mouseover: this.mouseOver.bind(this),
      mouseout: this.mouseOut.bind(this),
      click: this.selectRegion.bind(this)
    });
  }

  mouseOver(layer) {
    this.mouseOverLayer = layer;
  }

  mouseOut(layer) {
    this.mouseOverLayer = null;
  }

  getItemByLayout(target: any): any {
    let item: any;
    item = _.find(
      this.selectedRegions,
      region =>
        region.feature.properties.Name === target.feature.properties.Name
    );

    return item;
  }

  removeItemByLayout(e: any) {
    _.remove(
      this.selectedRegions,
      region =>
        region.feature.properties.Name === e.target.feature.properties.Name
    );
  }

  removeRegion(regionToRemove: any) {
    _.remove(
      this.selectedRegions,
      region =>
        region.feature.properties.Name ===
        regionToRemove.feature.properties.Name
    );
    this.setAllStyles();
    this.cdr.detectChanges();
  }

  removeAllRegions() {
    this.selectedRegions = [];
    this.setAllStyles();
    this.cdr.detectChanges();
  }

  selectRegion(e: any) {
    const item: any = this.getItemByLayout(e.target);

    if (item) {
      this.removeItemByLayout(e);
      this.setAllStyles();
      this.cdr.detectChanges();
      return;
    }
    this.selectedRegions.push(e.target);
    this.selectedRegions.sort((a, b) => {
      if (a.feature.properties.Name < b.feature.properties.Name) {
        return -1;
      }
      if (a.feature.properties.Name > b.feature.properties.Name) {
        return 1;
      }
      return 0;
    });
    this.setAllStyles();
    this.cdr.detectChanges();
  }

  setAllStyles() {
    this.map.eachLayer((layer: any) => {
      if (layer.feature) {
        layer.setStyle(this.style(layer.feature));
        if (
          this.selectedRegions.find((r: any) => {
            return r.feature.properties.Name === layer.feature.properties.Name;
          }) &&
          !L.Browser.ie &&
          !L.Browser.opera12 &&
          !L.Browser.edge
        ) {
          layer.bringToFront();
        }
      }
    });
  }

  style(feature: any) {
    return {
      fillColor: this.getGeoRegionColor(feature.properties.Name),
      weight: 2,
      opacity: 1,
      color: this.getGeoBorderColor(feature.properties.Name),
      dashArray: this.getGeoDashArray(feature.properties.Name),
      fillOpacity: 0.7
    };
  }

  getGeoBorderColor(geoRegion: any) {
    if (
      this.selectedRegions.find((r: any) => {
        return r.feature.properties.Name === geoRegion;
      })
    ) {
      return 'black';
    }
    return 'white';
  }

  getGeoDashArray(geoRegion: any) {
    if (
      this.selectedRegions.find((r: any) => {
        return r.feature.properties.Name === geoRegion;
      }) ||
      this.mouseOverLayer === geoRegion
    ) {
      return '';
    }
    return '3';
  }

  getGeoRegionColor(geoRegion: any) {
    const result = this.results.find((r: any) => {
      return r.geoRegion === geoRegion;
    });
    if (result === undefined) {
      return null;
    } else if (result == '0') {
      return 'white';
    }

    return this.getColor(result.dataValue);
  }

  getColor(d: any) {
    const orderedLegendGrades: LegendGrade[] = _.orderBy(
      this.legendGrades,
      'orderBy',
      'desc'
    );
    for (let i = 0; i < orderedLegendGrades.length; i++) {
      if (d >= orderedLegendGrades[i].gradeValue) {
        return orderedLegendGrades[i].legendColor;
      }
    }
  }

  onMapReady(pMap: L.Map) {
    this.map = pMap;
    this.map.doubleClickZoom.disable();
  }

  addLegend() {
    if (this.map && this.legend) {
      this.map.removeControl(this.legend);
    }
    this.legend = new L.Control({ position: 'bottomleft' });

    const that = this;
    this.legend.onAdd = function() {
      const div = L.DomUtil.create('div', 'legend'),
        grades = _.map(that.legendGrades, 'gradeValue'),
        labels = [];
      if (grades.length) {
        // loop through our density intervals and generate a label with a colored square for each interval
        div.innerHTML +=
          '<i style="background:' +
          that.legendGrades[0].legendColor +
          '"></i> <' +
          grades[1].toLocaleString() +
          '<br>';
        for (let i = 1; i < grades.length - 1; i++) {
          div.innerHTML +=
            '<i style="background:' +
            that.legendGrades[i].legendColor +
            '"></i> ' +
            grades[i].toLocaleString() +
            ' < ' +
            grades[i + 1].toLocaleString() +
            '<br>';
        }
        div.innerHTML +=
          '<i style="background:' +
          that.legendGrades[grades.length - 1].legendColor +
          '"></i> ' +
          grades[grades.length - 1].toLocaleString() +
          '+';
      }
      return div;
    };

    this.legend.addTo(this.map);
  }

  addRegion(e) {
    if (
      _.find(this.selectedRegions, region => {
        return e.target.value === region.feature.properties.Name;
      })
    ) {
      alert('Region is already selected');
      return;
    }
    const regionToAdd = _.find(this.regions.features, region => {
      return e.target.value === region.properties.Name;
    });
    if (regionToAdd == null) {
      alert('Region not found');
      return;
    }
    this.setAllStyles();
    this.cdr.detectChanges();
    this.regionInput = '';
    return true;
  }

  getValue(geoRegion: any): string {
    const result = this.results.find(
      (r: any) => r.geoRegion === geoRegion.feature.properties.Name
    );
    if (result) {
      if (result.dataValue == '0') {
        return '0';
      } else {
        return result.dataValue.toLocaleString();
      }
    } else {
      return 'No Data';
    }
  }

  zoomToRegion(region: any) {
    this.map.eachLayer((layer: any) => {
      if (
        layer.feature &&
        layer.feature.properties.Name === region.feature.properties.Name
      ) {
        this.map.fitBounds(layer.getBounds());
      }
    });
    this.cdr.detectChanges();
  }

  autoDefineLegend(legends: any) {
    this.legendGrades = [];
    if (legends && legends.length > 0) {
      for (let i = 0; i < legends.length; i++) {
        const legend: LegendGrade = {
          gradeValue: legends[i].gradeValue,
          legendColor: legends[i].legendColor,
          gradeValueFormat: legends[i].gradeValueFormat,
          orderBy: legends[i].orderBy
        };
        this.legendGrades.push(legend);
      }
    } else {
      let maxItem: any = _.maxBy(this.results, 'dataValue');
      let minItem: any = _.minBy(this.results, 'dataValue');
      let maxValue: any;
      let minValue: any;

      //set percentage range
      if (this.dataService.selectedMeasure.MeasureDataFormat == 'percentage') {
        //1) Percentage
        maxValue = 100;
        minValue = 0;
      } else if (!maxItem || !maxItem.dataValue) {
        console.log('Cannot find the max data value for defining the Legend');
        return;
      } else {
        // if (!minItem || !minItem.dataValue) {
        minValue = 0;
        // } else {
        //  minValue = Math.pow(10, Math.floor(Math.log10(minValue.dataValue)));
        // }

        // maxValue = Math.pow(10, Math.ceil(Math.log10(maxItem.dataValue)));
        maxValue = maxItem.dataValue;

        console.log('Max Data value' + maxItem.dataValue);
        console.log('Max 10th Power Value ' + maxItem.dataValue);

        let gap: any = (maxValue - minValue) / 4;
        let colors: string[] = [
          '#FFEDA0',
          '#FED976',
          '#FEB24C',
          '#FD8D3C',
          '#FC4E2A',
          '#E31A1C'
        ];
        let endValue: any = minValue;
        for (let i = 0; i < 6; i++) {
          const legend: LegendGrade = {
            gradeValue: endValue,
            legendColor: colors[i],
            gradeValueFormat: this.dataService.selectedMeasure
              .MeasureDataFormat,
            orderBy: i
          };
          this.legendGrades.push(legend);
          endValue = endValue + gap;
        }

        //a)
        //let max: any = maxItem.dataValue / 10;
        //maxValue = (parseInt(max, 10) + 1) * 10;

        //b)
        //let no: any = Math.pow(10, Math.round(Math.log10(maxItem.dataValue)));
      }
    }
  }
}
