import { Measure, MeasureGroup } from './../../classes/classes';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataService } from '../../../app/services/data.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-measure-dialog',
  templateUrl: './measure-dialog.component.html',
  styleUrls: ['./measure-dialog.component.css']
})
export class MeasureDialogComponent implements OnInit {
  measures: Measure[] = [];
  selectedMeasure: Measure;
  selectedMeasureGroup: MeasureGroup;
  isSingleClick = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public pMeasureGroups: any,
    public dialogRef: MatDialogRef<MeasureDialogComponent>,
    public dataService: DataService,
    private localStorage: LocalStorageService
  ) {
    if (pMeasureGroups && pMeasureGroups.length > 0) {
      this.dataService.measureGroups = pMeasureGroups;
    }
  }

  ngOnInit() {
    

    if (this.dataService.selectedMeasureGroup) {
      this.selectedMeasureGroup = this.dataService.selectedMeasureGroup;
      this.onMeasureGroupSelected(this.selectedMeasureGroup);
    }
    else if (this.localStorage.retrieve('selectedMeasureGroup')) {
      this.dataService.selectedMeasureGroup = this.localStorage.retrieve('selectedMeasureGroup');
      this.selectedMeasureGroup = this.localStorage.retrieve('selectedMeasureGroup');
      this.onMeasureGroupSelected(this.selectedMeasureGroup);
    }

    if (this.dataService.selectedMeasure) {
      this.selectedMeasure = this.dataService.selectedMeasure;
    }
    else if (this.localStorage.retrieve('selectedMeasure')) {
      this.dataService.selectedMeasure = this.localStorage.retrieve('selectedMeasure');
      this.selectedMeasure = this.localStorage.retrieve('selectedMeasure');
    }
  }

  onMeasureGroupSelected(selectedMeasureGroup: MeasureGroup) {
    this.selectedMeasure = null;  
    this.selectedMeasureGroup = selectedMeasureGroup;
    this.measures = this.dataService.getMeasuresByGroup(
      selectedMeasureGroup.measureGroupId
    );
  }

  onMeasureSelected(measure: Measure) {
    this.selectedMeasure = measure;
  }

  onCancelClicked() {
    this.dialogRef.close(null);
  }

  onOkClicked() {
    this.localStorage.store('selectedMeasureGroup', this.selectedMeasureGroup);
    this.localStorage.store('selectedMeasure', this.selectedMeasure);

    this.dialogRef.close({
      measureGroup: this.selectedMeasureGroup,
      measure: this.selectedMeasure
    });
  }
}
